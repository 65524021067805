import * as React from 'react'
import Layout from '../components/layout'

const WhatPage = () => {
  return (
    <Layout pageTitle="G13 Railway Operations Model -  What ROM Does">
      <p><strong>WHAT THE ROM™ DOES</strong><br />
        <strong>It Asks:</strong></p>
      <ul>
        <li>What and how much cargo is to be transported;</li>
        <li>What type of train is to be provided; </li>
        <li>Which route is to be taken; and</li>
        <li>Am I an operator, track authority, integrated railway or a hybrid of these?</li>
      </ul>
      <p><strong>It Computes:</strong></p>
      <ul>
        <li>The amount of rolling stock, fuel and crews required;</li>
        <li>Track utilisation in terms of haulage and capacity used;</li>
        <li>Above-rail and below-rail cost; and</li>
        <li>Freight and track access revenues.</li>
      </ul>
      <p><strong>It Produces:</strong></p>
      <ul>
        <li>Tables of physical inputs and outputs;</li>
        <li>Maps showing track utilisation, cost, and revenue by segments;</li>
        <li>Statements of capital, earnings and cash flow; and</li>
        <li>Facility to export data and results for bespoke analysis.</li>
      </ul>
      <p><strong>It Improves:</strong></p>
      <ul>
        <li>Negotiation and investment evaluation;</li>
        <li>Annual and medium term business plans;</li>
        <li>Comparison between railway operational and infrastructure options; and</li>
        <li>Comparison with alternative transport modes.</li>
      </ul>
    </Layout>
  )
}
// Step 3: Export your component
export default WhatPage